<template>
    <ODataLookup :data-object="dsInterfaces" contextField="OrgUnitIdPath">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue" style="max-height:30px">
            <slot v-else :target="target" />
        </template>
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="300"></OColumn>
        <OColumn field="OrgUnit" width="200"></OColumn>
    </ODataLookup>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,
});

const dsInterfaces = $getDataObjectById("dsComplete_InterfacesLookup");

</script>